<template>
  <footer class="site-footer">
    <div class="footer-content">
      <div class="links">
        <router-link to="/terms" class="link">服务条款</router-link>
        <span class="divider">|</span>
        <router-link to="/privacy" class="link">隐私政策</router-link>
      </div>
      <div class="copyright">
        <span>版权所有 © mailcat.online</span>
        <span class="divider">|</span>
        <a href="https://beian.miit.gov.cn" target="_blank" class="beian">京ICP备2025105345号</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter'
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.site-footer {
  background-color: $background-white;
  padding: $spacing-base 0;
  margin-top: auto;
  border-top: 1px solid $border-color;
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    
    .links {
      margin-bottom: 10px;
      
      .link {
        color: $text-regular;
        text-decoration: none;
        font-size: 14px;
        
        &:hover {
          color: $primary-color;
        }
      }
    }
    
    .copyright {
      color: $text-secondary;
      font-size: 12px;
      
      .beian {
        color: $text-secondary;
        text-decoration: none;
        
        &:hover {
          color: $primary-color;
        }
      }
    }
    
    .divider {
      margin: 0 10px;
      color: $text-secondary;
    }
  }
}
</style> 