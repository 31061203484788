<template>
  <div class="app-wrapper">
    <site-navbar />
    <div class="main-container">
      <app-main />
    </div>
    <site-footer />
  </div>
</template>

<script>
import SiteNavbar from './components/SiteNavbar'
import AppMain from './components/AppMain'
import SiteFooter from './components/Footer'

export default {
  name: 'BasicLayout',
  components: {
    SiteNavbar,
    AppMain,
    SiteFooter
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.app-wrapper {
  min-height: 100vh;
  background-color: $background-page;
  display: flex;
  flex-direction: column;
  
  .main-container {
    padding: $spacing-large;
    flex: 1;
  }
}
</style> 