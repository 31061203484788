<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

html {
  height: 100%;
  box-sizing: border-box;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
  background-color: $background-page;
}

#app {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* 统一卡片样式 */
.el-card {
  border-radius: $border-radius-large;
  border: none;
  box-shadow: $box-shadow;
}

/* 统一按钮样式 */
.el-button {
  border-radius: $border-radius;
  font-weight: 500;
}

/* 统一输入框样式 */
.el-input__inner {
  border-radius: $border-radius;
}

/* 统一表格样式 */
.el-table {
  border-radius: $border-radius;
  overflow: hidden;
}
</style>
