import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/api/user/login',
    method: 'post',
    params: {
      identifier: data.username,
      password: data.password
    }
  })
}

export function getCaptcha() {
  return request({
    url: '/api/user/captcha',
    method: 'get',
    responseType: 'blob',
    headers: {
      'Accept': 'image/jpeg',
      'Cache-Control': 'no-cache'
    }
  })
}

export function register(data) {
  return request({
    url: '/api/user/register',
    method: 'post',
    params: {
      username: data.username,
      password: data.password,
      captchaId: data.captchaId,
      captchaText: data.captchaText
    }
  })
}

export function getRemainingPoints() {
  return request({
    url: '/api/user/remaining-points',
    method: 'get'
  })
}

// 发送邮箱验证码
export function sendEmailVerification(email) {
  return request({
    url: '/api/user/send-email-verification',
    method: 'post',
    params: { email }
  })
}

// 绑定邮箱
export function bindEmail(email, captcha) {
  return request({
    url: '/api/user/bind-email',
    method: 'post',
    params: { 
      email,
      captcha
    }
  })
}

export function sendPhoneVerification(phone) {
  return request({
    url: '/api/user/sendSmsCode',
    method: 'post',
    params: { phone }
  })
}

export function bindPhone(phone, code) {
  return request({
    url: '/api/user/bindPhone',
    method: 'post',
    params: {
      phone,
      code
    }
  })
}

export function getPointHistory(params) {
  return request({
    url: '/api/user/point-history',
    method: 'get',
    params
  })
} 