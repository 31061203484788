import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 5000,
  withCredentials: true,
  crossDomain: true
})


// 请求拦截器
service.interceptors.request.use(
  config => {
    // 如果有token，添加到请求头
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = token
    }
    // 设置通用请求头
    // config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 如果是blob类型（验证码图片），直接返回response
    if (response.config.responseType === 'blob') {
      return response
    }
    const res = response.data
    if (res.code !== 0) {
      // 返回一个包含完整错误信息的对象
      return Promise.reject({
        code: res.code,
        message: res.message || '请求失败'
      })
    }
    return res
  },
  error => {
    if (error.response && error.response.status === 401) {
      // token 失效，清除用户信息
      store.dispatch('user/logout')
      router.push('/login')
    }
    // 显示错误信息
    Message({
      message: error.message || '网络请求失败',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service 