<template>
  <div class="site-navbar">
    <div class="left-menu">
      <router-link to="/">
        <img src="@/assets/logo.png" class="logo">
      </router-link>
      <div class="tabs">
        <router-link 
          v-for="route in mainRoutes"
          :key="route.path"
          :to="{ path: route.path }"
          class="tab-item"
          active-class="router-link-active"
        >
          {{ route.meta.title }}
        </router-link>
      </div>
    </div>

    <div class="right-menu">
      <template v-if="!isLogin">
        <router-link to="/login">
          <el-button type="text">登录</el-button>
        </router-link>
        <router-link to="/register">
          <el-button type="primary">注册</el-button>
        </router-link>
      </template>
      <div v-else class="user-info">
        <span class="username" @click="goToMemberCenter">{{ username }}</span>
        <el-button type="text" @click="handleLogout">退出</el-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.site-navbar {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacing-large;
  background: $background-white;
  box-shadow: $box-shadow;

  .left-menu {
    display: flex;
    align-items: center;

    .logo {
      height: 32px;
      margin-right: 40px;
    }

    .tabs {
      display: flex;
      gap: 20px;

      .tab-item {
        color: $text-regular;
        text-decoration: none;
        font-size: 14px;
        padding: 0 $spacing-base;
        height: 50px;
        line-height: 50px;
        border-bottom: 2px solid transparent;

        &.router-link-active {
          color: $primary-color;
          font-weight: 500;
          border-bottom-color: $primary-color;
        }

        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .right-menu {
    display: flex;
    align-items: center;
    
    .el-button {
      margin-left: 15px;
    }

    .user-info {
      display: flex;
      align-items: center;
      
      .username {
        font-size: 14px;
        color: #409EFF;
        margin-right: 15px;
        cursor: pointer;
        transition: all 0.3s;
        
        &:hover {
          color: #66b1ff;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SiteNavbar',
  computed: {
    ...mapState({
      isLogin: state => state.user.token,
      username: state => state.user.username,
      currentPath: state => state.route.path
    }),
    mainRoutes() {
      const rootRoute = this.$router.options.routes.find(r => r.path === '/')
      return rootRoute?.children?.filter(route => 
        route.meta?.title && !route.hidden
      ) || []
    }
  },
  methods: {
    async handleLogout() {
      await this.$store.dispatch('user/logout')
      this.$message.success('已退出登录')
      this.$router.push('/login')
    },
    goToMemberCenter() {
      if (this.$route.path === '/verify/member') {
        return
      }
      this.$router.push('/verify/member').catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
    }
  },
  mounted() {
    console.log('Main Routes:', this.mainRoutes.map(r => r.path))
    // 预期输出: ['/verify', '/pricing', '/faq', '/contact', '/community']
  }
}
</script> 