import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'
// import { Message } from 'element-ui'
import store from '@/store' // 引入 Vuex store

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '', // 根路径
        component: () => import('@/views/verify/index'), // 加载导航菜单
        children: [
          {
            path: '', // 空路径作为默认子路由
            component: () => import('@/views/verify/single'),
            meta: { title: '单个验证' }
          },
          {
            path: 'single',
            component: () => import('@/views/verify/single'),
            meta: { title: '单个验证' }
          },
          {
            path: 'batch',
            component: () => import('@/views/verify/batch'),
            meta: { title: '批量验证', requiresAuth: true }
          },
          {
            path: 'api',
            component: () => import('@/views/verify/api'),
            meta: { title: 'API集成', requiresAuth: true }
          },
          {
            path: 'history',
            component: () => import('@/views/verify/history'),
            meta: { title: '历史记录', requiresAuth: true }
          },
          {
            path: 'member',
            component: () => import('@/views/verify/member'),
            meta: { title: '会员中心', requiresAuth: true }
          }
        ]
      },
      {
        path: '/verify', 
        component: () => import('@/views/verify/index'), // 加载导航菜单
        meta: { title: '邮箱验证' },
        children: [
          {
            path: '', // 空路径作为默认子路由
            component: () => import('@/views/verify/single'),
            meta: { title: '单个验证' }
          },
          {
            path: 'single',
            component: () => import('@/views/verify/single'),
            meta: { title: '单个验证' }
          },
          {
            path: 'batch',
            component: () => import('@/views/verify/batch'),
            meta: { title: '批量验证', requiresAuth: true }
          },
          {
            path: 'api',
            component: () => import('@/views/verify/api'),
            meta: { title: 'API集成', requiresAuth: true }
          },
          {
            path: 'history',
            component: () => import('@/views/verify/history'),
            meta: { title: '历史记录', requiresAuth: true }
          },
          {
            path: 'member',
            component: () => import('@/views/verify/member'),
            meta: { title: '会员中心', requiresAuth: true }
          }
        ]
      },
      // {
      //   path: '/pricing',
      //   component: () => import('@/views/pricing/index'),
      //   meta: { title: '产品价格' }
      // },
      {
        path: '/faq',
        component: () => import('@/views/faq/index'),
        meta: { title: '常见问题' }
      },
      {
        path: '/contact',
        component: () => import('@/views/contact/index'),
        meta: { title: '联系客服' }
      },
      {
        path: '/community',
        component: () => import('@/views/community/index'),
        meta: { title: '邮箱社区' }
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/register',
    component: () => import('@/views/register/index'), 
    hidden: true
  },
  {
    path: '/privacy',
    component: () => import('@/views/privacy/index'),
    hidden: true
  },
  {
    path: '/terms',
    component: () => import('@/views/terms/index'),
    hidden: true
  },
  {
    path: '/order/confirm',
    component: () => import('@/views/order/confirm'),
    children: [{
      path: '',
      name: 'OrderConfirm',
      meta: { title: '支付确认' }
    }]
  },
  {
    path: '/community/doc/:id',
    component: Layout,
    hidden: true,
    children: [{
      path: '',
      component: () => import('@/views/community/_id'),
      meta: { title: '文档详情' }
    }]
  }
] 

const createRouter = () => new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

router.beforeEach((to, from, next) => {
  // 确保在路由切换时清理所有 Element UI 的 Message
  // Message.closeAll()
  // next()
  const isAuthenticated = store.state.user.token
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router 