import { login } from '@/api/user'

const state = {
  token: localStorage.getItem('token'),
  username: localStorage.getItem('username'),
  email: localStorage.getItem('email'),
  phoneNumber: localStorage.getItem('phoneNumber'),
  verifiedEmail: localStorage.getItem('verifiedEmail') === 'true',
  verifiedPhone: localStorage.getItem('verifiedPhone') === 'true',
  apiKey: localStorage.getItem('apiKey'),
  inviteCode: localStorage.getItem('inviteCode'),
  avatar: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    localStorage.setItem('token', token)
  },
  SET_USERNAME: (state, username) => {
    state.username = username
    localStorage.setItem('username', username)
  },
  SET_EMAIL: (state, email) => {
    state.email = email
    localStorage.setItem('email', email)
  },
  SET_PHONE: (state, phoneNumber) => {
    state.phoneNumber = phoneNumber
    localStorage.setItem('phoneNumber', phoneNumber)
  },
  SET_VERIFIED_EMAIL: (state, verified) => {
    state.verifiedEmail = verified
    localStorage.setItem('verifiedEmail', verified)
  },
  SET_VERIFIED_PHONE: (state, verified) => {
    state.verifiedPhone = verified
    localStorage.setItem('verifiedPhone', verified)
  },
  SET_API_KEY: (state, apiKey) => {
    state.apiKey = apiKey
    localStorage.setItem('apiKey', apiKey)
  },
  SET_INVITE_CODE: (state, inviteCode) => {
    state.inviteCode = inviteCode
    localStorage.setItem('inviteCode', inviteCode)
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  }
}

const actions = {
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(response => {
        if (response.code !== 0) {
          reject(new Error(response.message))
          return
        }
        const { 
          token, 
          username,
          email = '',
          phoneNumber = '',
          verifiedEmail = false,
          verifiedPhone = false,
          apiKey = '',
          inviteCode = ''
        } = response.data
        commit('SET_TOKEN', token)
        commit('SET_USERNAME', username || userInfo.username)
        commit('SET_EMAIL', email)
        commit('SET_PHONE', phoneNumber)
        commit('SET_VERIFIED_EMAIL', verifiedEmail)
        commit('SET_VERIFIED_PHONE', verifiedPhone)
        commit('SET_API_KEY', apiKey)
        commit('SET_INVITE_CODE', inviteCode)
        setTimeout(() => {
          resolve()
        }, 0)
      }).catch(error => {
        reject(new Error(error.message || '登录失败'))
      })
    })
  },
  
  logout({ commit }) {
    commit('SET_TOKEN', '')
    commit('SET_USERNAME', '')
    commit('SET_EMAIL', '')
    commit('SET_PHONE', '')
    commit('SET_VERIFIED_EMAIL', false)
    commit('SET_VERIFIED_PHONE', false)
    commit('SET_API_KEY', '')
    commit('SET_INVITE_CODE', '')
    commit('SET_AVATAR', '')
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    localStorage.removeItem('email')
    localStorage.removeItem('phoneNumber')
    localStorage.removeItem('verifiedEmail')
    localStorage.removeItem('verifiedPhone')
    localStorage.removeItem('apiKey')
    localStorage.removeItem('inviteCode')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
} 